<template>
    <ValidationObserver v-slot="{ invalid, validated }">
      <v-navigation-drawer
        v-model="showNewSheet"
        app
        clipped
        right
        width="800"
        :permanent="$vuetify.breakpoint.mdAndDown"
        >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
              <v-list-item-title v-else class="title"> New </v-list-item-title>
              <v-list-item-subtitle>Oncall Handoff</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              icon
              color="info"
              :loading="loading"
              :disabled="invalid || !validated"
              @click="save()"
            >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="secondary" @click="closeNewSheet">
            <v-icon>close</v-icon>
          </v-btn>
          </v-list-item>
        </template>
        <v-card flat>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <ValidationProvider name="Description" rules="required" immediate>
                                    <individual-select
                                    v-model="handover_from"
                                    slot-scope="{ errors, valid }"
                                    :error-messages="errors"
                                    :success="valid"
                                    label="Handover from"
                                    hint="The organization member who is handing off on call"
                                    clearable
                                    :project="project"
                                    immediate
                                    />
                                </ValidationProvider>
                            </v-flex>
                            <v-flex xs12>
                                <ValidationProvider name="Description" rules="required" immediate>
                                    <individual-select
                                    v-model="handover_to"
                                    label="Handover To"
                                    slot-scope="{ errors, valid }"
                                    :error-messages="errors"
                                    :success="valid"
                                    hint="The organization member who is taking over on call"
                                    clearable
                                    :project="project"
                                    immediate
                                    />
                                </ValidationProvider>
                            </v-flex>
                            <v-flex xs12>
                                <ValidationProvider name="Description" rules="required" immediate>
                                    <project-select
                                    v-model="project"
                                    immediate
                                />
                                </ValidationProvider>
                            </v-flex>
                            <v-flex xs12>
                                <date-time-picker-menu
                                    v-model="start_time"
                                    label="Start Time"
                                ></date-time-picker-menu>
                            </v-flex>
                            <v-flex xs12>
                                <date-time-picker-menu
                                    v-model="end_time"
                                    label="End Time"

                                ></date-time-picker-menu>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="shift_type"
                                    label="Shift Type"

                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="location_type"
                                    label="Location Type"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="location_details"
                                    label="Location Details"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea
                                    v-model="critical_ongoing_issues"
                                    label="Critical Ongoing Issues"
                                    rows="4"
                                    auto-grow
                                    hint="Any ongoing critical incidents or issues that the next person should be aware of immediately."
                                ></v-textarea>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea
                                    v-model="tasks_completed"
                                    label="Tasks Completed"
                                    rows="4"
                                    auto-grow
                                ></v-textarea>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea
                                    v-model="pending_tasks"
                                    label="Pending Tasks"
                                    rows="4"
                                    auto-grow
                                ></v-textarea>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea
                                    v-model="mentions"
                                    label="Mentions"
                                    rows="4"
                                    auto-grow
                                    hint="Mentions of other people or teams that may be relevant"
                                ></v-textarea>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea
                                    v-model="tools_and_access"
                                    label="Tools and Access"
                                    rows="4"
                                    auto-grow
                                    hint="Any tools, systems, or services the incoming on call needs to have access to or know about."
                                ></v-textarea>
                            </v-flex>
                            <v-flex xs12>
                                <v-checkbox
                                    v-model="handoff_completed"
                                    label="Handoff Completed"
                                    color="primary"
                                ></v-checkbox>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </ValidationObserver>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import { ValidationObserver, extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import IndividualSelect from "@/individual/IndividualSelect.vue"
import ProjectSelect from "@/project/ProjectSelect.vue"
import DateTimePickerMenu from "@/components/DateTimePickerMenu.vue"


extend("required", {
    ...required,
    message: "This field is required",
})

export default {
    name: "OncallHandoffNewSheet",


components: {
    ValidationObserver,
    ValidationProvider,
    IndividualSelect,
    ProjectSelect,
    DateTimePickerMenu,
},
computed: {
    ...mapFields("oncall_handoff", [
        "selected.handover_from",
        "selected.handover_to",
        "selected.start_time",
        "selected.end_time",
        "selected.shift_type",
        "selected.location_type",
        "selected.location_details",
        "selected.critical_ongoing_issues",
        "selected.tasks_completed",
        "selected.pending_tasks",
        "selected.mentions",
        "selected.tools_and_access",
        "selected.handoff_completed",
        "dialogs.showCreateEdit",
        "selected.project",
        "selected.id",
        "selected.loading",
        "dialogs.showNewSheet",
    ]),
    ...mapFields("route", ["query"]),
},
data() {
    return {
    }
},

methods: {
    fetchDetails() {
        this.getDetails({ id: this.$route.params.id })
    },
    ...mapActions("oncall_handoff", ["save", "closeCreateEdit", "getDetails", "closeNewSheet"]),
},
watch: {
    "$route.params.id": function () {
        this.fetchDetails()
    },
},
created() {
    if (this.query.project) {
        this.project = { name: this.query.project }
    }
    this.fetchDetails()
},
}
</script>
